import './App.css';
import Loader from './components/Loader';
import { useEffect, useState, useCallback } from 'react';
import {BrowserRouter, useFetcher} from 'react-router-dom';
import Particles from "react-tsparticles";
import Navbar from './components/Navbar';
import Hero from "./components/Hero";
import Carousel from "./components/Carousel";
import Services from "./components/Services";
import Portfolio from "./components/Portfolio";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import { loadFull } from "tsparticles";

function App() {
    function checkLoaderCookie() {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith('loaderSession' + '=')) {
                return cookie.substring('loaderSession='.length) === 'true';
            }
        }
        return false;
    }
    const particlesInit = useCallback(async engine => {

        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {

    }, []);

    const [loaderVisible, setLoaderVisible] = useState(true);

    useEffect(() => {
        /*Loader guardian*/
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (isSafari) {
            setLoaderVisible(false);
        }
        if(checkLoaderCookie()){
            setLoaderVisible(false);
        }
        const handleLoader = () => {
            if (window.innerWidth < 640) {
                setLoaderVisible(false);
            } else {
                const loaderTimeout = setTimeout(() => {
                    setLoaderVisible(false);
                    let currentDate = new Date();
                    let expirationMili = new Date(currentDate.getTime() + 10 * 60 * 1000);
                    let expires = expirationMili.toUTCString();
                    document.cookie = "loaderSession=true; expires=" + expires + "; path=/";

                }, 10000);

                return () => {
                    clearTimeout(loaderTimeout);
                };
            }
        };

        handleLoader();
        window.addEventListener('resize', handleLoader);

        return () => {
            window.removeEventListener('resize', handleLoader);
        };
    }, []);

    return (
        <div className="App">
            {loaderVisible ? (
                <Loader />
            ) : (
                <BrowserRouter>
                    <Navbar />
                    <div className="particles-container">
                        <Particles
                            id="tsparticles"
                            url="/config/particles.json"
                            init={particlesInit}
                            loaded={particlesLoaded}
                        />
                        <Hero />
                    </div>
                    <Carousel />
                    <Services />
                    <Portfolio />
                    <Contact />
                    <Footer />
                </BrowserRouter>
            )}
        </div>
    );
}

export default App;
