import { useState, useEffect } from "react";

function Loader() {
    const [videoEnded, setVideoEnded] = useState(false);
    const [hideLoader, setHideLoader] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const handleVideoEnd = () => {
        setVideoEnded(true);
    };

    const updateScreenWidth = () => {
        setScreenWidth(window.innerWidth);
    };

    useEffect(() => {
        if (videoEnded) {
            const hideTimeout = setTimeout(() => {
                setHideLoader(true);
            }, 5500);
            return () => clearTimeout(hideTimeout);
        }
    }, [videoEnded]);

    useEffect(() => {
        window.addEventListener("resize", updateScreenWidth);
        return () => {
            window.removeEventListener("resize", updateScreenWidth);
        };
    }, []);

    // Don't display loader if screen width is less than 640px
    if (screenWidth < 640) {
        return null;
    }

    return (
        <section id="loader-box" className={hideLoader ? "hide" : ""}>
            {videoEnded ? null : (
                <video
                    src="media/smoke.mp4"
                    autoPlay
                    muted
                    className="video-element"
                    onEnded={handleVideoEnd}
                ></video>
            )}
            <h1 className={videoEnded ? "hide" : ""}>
                <span>A</span>
                <span>R</span>
                <span>T</span>
                <span>I</span>
                <span>S</span>
                <span>A</span>
                <span>N</span>&nbsp;
                <span> </span>
                <span>C</span>
                <span>O</span>
                <span>D</span>
                <span>E</span>
            </h1>
        </section>
    );
}

export default Loader;
