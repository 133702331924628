import React from "react";
import styled, { keyframes, css } from "styled-components";
function Carousel(){
    const row1 = [
        {src: "img/laravel.png", alt: "Laravel"},
        {src: "img/vue.png", alt: "Vue.js"},
        {src: "img/wordpress.png", alt: "WordPress"},
        {src: "img/react.png", alt: "React"},
        {src: "img/nginx.png", alt: "Nginx"},
        {src: "img/elementor.png", alt: "Elementor"},
        {src: "img/livewire.png", alt: "Livewire"},
    ];
    let preventDragHandler = (e) => {
        e.preventDefault();
    }
    return (
        <section id="technologies">
            <Wrapper id="carousel-wrap">
                <Text>Nasze Narzędzia</Text>
                <Note>Odkryj narzędzia i frameworki, które napędzają nasze projekty!</Note>
                <Marquee>
                    <MarqueeGroup>
                        {row1.map((el, index) => (
                            <ImageGroup key={index}>
                                <Image onDragStart={preventDragHandler} alt={el.alt} src={el.src} />
                            </ImageGroup>
                        ))}
                    </MarqueeGroup>
                    <MarqueeGroup>
                        {row1.map((el, index) => (
                            <ImageGroup key={index}>
                                <Image alt={el.alt} src={el.src} />
                            </ImageGroup>
                        ))}
                    </MarqueeGroup>
                </Marquee>
            </Wrapper>
        </section>

    );
}
export default Carousel;
const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  color: #000000;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Text = styled.div`
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #00fefb;
`;

const Note = styled.div`
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 40px;
  color: #7c8e9a;
`;

const Marquee = styled.div`
  display: flex;
  width: 1200px;
  overflow: hidden;
  user-select: none;

  mask-image: linear-gradient(
          to right,
          hsl(190 100% 50% / 0),
          hsl(190 100% 50% / 1) 10%,
          hsl(190 100% 50% / 1) 90%,
          hsl(190 100% 50% / 0)
  );
`;

const scrollX = keyframes`
  from {
    left: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const common = css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width: 100%;
  animation: ${scrollX} 30s linear infinite;
`;

const MarqueeGroup = styled.div`
  ${common}
`;
const MarqueeGroup2 = styled.div`
  ${common}
  animation-direction: reverse;
  animation-delay: -3s;
`;

const ImageGroup = styled.div`
  display: grid;
  place-items: center;
    width: 180px;
    padding: 5px 20px;
`;

const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  border-radius: 0.5rem;
  aspect-ratio: 16/9;
  padding: 5px 20px;
  box-shadow: hsla(190, 100%, 50%, 0.2) 0px 2px 8px 0px;

`;
