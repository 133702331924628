import React from "react";
import { motion } from 'framer-motion';
import { Box, Image } from "@chakra-ui/react";
import { Fade } from "react-awesome-reveal";

const AnimatedBox = motion(Box);

const Hero = () => {
    let preventDragHandler = (e) => {
        e.preventDefault();
    }

        return (
        <div className="relative pt-[50px] lg:pt-[10px] pb-[110px]" id="hero">
            <div className="container-f">
                <div className="flex sm:pt-[30px]  md:pt-[120px] flex-wrap -mx-4">
                    <div className="w-full lg:w-6/12 px-4">
                        <div className="hero-content">
                            <Fade direction="left">
                            <h2 className=" dark:text-gray-200 font-bold text-4xl sm:text-[42px] lg:text-[40px] xl:text-[45px] leading-snug mb-6">
                                <span style={{ color: "#00fefb" }}>Twoje Rozwiązania,</span><br />
                                <span style={{ color: "#0077a6" }}>Nasza Pasja</span>
                            </h2>

                            <p className="text-base mb-8 max-w-[520px] text-[#e5e7eb] text-[28px]">
                                Jesteśmy zespołem doświadczonych deweloperów, specjalizujących się w projektowaniu systemów CRM, stron internetowych, sklepów online oraz rozwijaniu różnorodnych interfejsów API. Nasza pasja to tworzenie efektywnych rozwiązań webowych i utrzymywanie kroku z ciągłymi zmianami w świecie technologii, aby przynosić życie Twoim projektom cyfrowym.

                            </p>

                            <ul className="flex flex-wrap items-center">
                                <li>

                                    <a href="#portfolio" target="_self" className="hero-btn py-4 px-6 md:px-9 lg:px-6 xl:px-9 rounded leading-normal border inline-block transition bg-primary border-primary text-white rounded-lg">
                                        Dowiedz się więcej
                                    </a>

                                </li>
                            </ul>
                            <div className="clients pt-16">
                                <h3 className="text-white mb-2 flex items-cener text-xs font-normal">
                                    Nasze ostatnie projekty
                                    <span className="bg-body-color ml-2 inline-block h-[1px] w-8"></span>
                                </h3>
                                <div className="flex items-center">
                                    <div className="mr-4 w-full py-3">
                                        <img onDragStart={preventDragHandler} src="img/jolcar-logo.png" alt="jolcar_logo" />
                                    </div>
                                    <div className="mr-4 w-full py-3">
                                        <img onDragStart={preventDragHandler} src="img/klinika-logo.png" alt="klinika_dla_maszyn_logo" />
                                    </div>
                                    <div className="mr-4 w-full py-3">
                                        <img onDragStart={preventDragHandler} src="img/mp12-logo.png" alt="mp12_logo" />
                                    </div>
                                </div>
                            </div>
                            </Fade>
                        </div>
                    </div>
                    <div className="hidden px-4 lg:block lg:w-1/12"></div>
                    <div className="w-full px-4 lg:w-5/12">
                        <div className="lg:ml-auto lg:text-right">
                            <div className="relative z-10 inline-block pt-11 lg:pt-0">
                                <motion.div
                                    initial={{ y: 0 }}
                                    animate={{ y: [0, -10, 0], transition: { repeat: Infinity, duration: 2, ease: "linear" } }}
                                >
                                <AnimatedBox boxSize='sm'  >
                                    <Fade direction="right">
                                    <Image onDragStart={preventDragHandler} id="hero-img" src="img/hero-img.png" alt="hero" className="max-w-full lg:ml-auto" />
                                    </Fade>

                                </AnimatedBox>
                                </motion.div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
