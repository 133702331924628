
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
function Navbar(){
    const [showNavbar, setShowNavbar] = React.useState(false);

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar);
    };


    return (
        <nav className="navbar">
            <div className="container-f">
                <div className="logo">
                    <Fade>
                        <Logo />
                    </Fade>
                </div>
                <div className="menu-icon" onClick={handleShowNavbar}>
                    <Hamburger />
                </div>
                <div className={`nav-elements ${showNavbar && "active"}`}>
                    <ul>
                        <li>
                            <Fade>
                                <a href="#hero-content">O nas</a>
                            </Fade>
                        </li>
                        <li>
                            <Fade>
                                <a href="#technologies">Technologie</a>
                            </Fade>
                        </li>
                        <li>
                            <Fade>
                                <a href="#portfolio">Projekty</a>
                            </Fade>
                        </li>
                        <li>
                            <Fade>
                                <a href="#services">Oferta</a>
                            </Fade>
                        </li>
                        <li>
                            <Fade>
                                <a href="#contact">Kontakt</a>
                            </Fade>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

    );
};


const Hamburger = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="52"
        height="24"
        viewBox="0 0 52 24"
    >
        <g id="Group_9" data-name="Group 9" transform="translate(-294 -47)">
            <rect
                id="Rectangle_3"
                data-name="Rectangle 3"
                width="42"
                height="4"
                rx="2"
                transform="translate(304 47)"
                fill="#00fefb"
            />
            <rect
                id="Rectangle_5"
                data-name="Rectangle 5"
                width="42"
                height="4"
                rx="2"
                transform="translate(304 67)"
                fill="#00fefb"
            />
            <rect
                id="Rectangle_4"
                data-name="Rectangle 4"
                width="52"
                height="4"
                rx="2"
                transform="translate(294 57)"
                fill="#00fefb"
            />
        </g>
    </svg>
);
let preventDragHandler = (e) => {
    e.preventDefault();
}
const Logo = () => (

    <img src="img/nav-logo.png"  onDragStart={preventDragHandler} alt="ArtisanCode logo"></img>
);

export default Navbar;