import {
    ArrowPathIcon,
    CloudArrowUpIcon,
    FingerPrintIcon,
    LockClosedIcon,
    ComputerDesktopIcon,
    DevicePhoneMobileIcon,
    QrCodeIcon,
    CodeBracketIcon,
} from '@heroicons/react/24/outline';

const services = [
    {
        name: 'Projektowanie stron internetowych i sklepów',
        description:
            'Oferujemy profesjonalne projektowanie i rozwijanie stron internetowych oraz sklepów online, dostosowane do Twoich potrzeb i oczekiwań.',
        icon: ComputerDesktopIcon,
    },
    {
        name: 'Projektowanie aplikacji desktopowych i mobilnych',
        description:
            'Tworzymy nowoczesne aplikacje desktopowe i mobilne, które zapewniają doskonałe wrażenia użytkownika i funkcjonalność.',
        icon: DevicePhoneMobileIcon,
    },
    {
        name: 'Projektowanie API i systemów CRM',
        description:
            'Zajmujemy się projektowaniem interfejsów API oraz systemów CRM, które ułatwiają zarządzanie danymi i procesami w Twojej firmie.',
        icon: CodeBracketIcon,
    },
    {
        name: 'Projektowanie autorskich systemów CMS',
        description:
            'Tworzymy spersonalizowane systemy zarządzania treścią, które pozwalają łatwo zarządzać treściami na Twojej stronie internetowej.',
        icon: QrCodeIcon,
    },
];

export default function Services() {
    return (
        <div className="services-container" id='services'>
            <div className="py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:text-center">
                        <h2 className="text-base font-semibold leading-7" style={{ color: '#0066b3' }}>*****</h2>
                        <p className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl" style={{ color: '#0077a6' }}>
                            Oferowane usługi
                        </p>
                        <p className="mt-6 text-lg leading-8 " style={{ color: '#ced0d4' }}>
                            Dowiedz się, jakie usługi oferujemy, by wesprzeć Twój biznes. Specjalizujemy się w projektowaniu i wdrażaniu różnorodnych rozwiązań cyfrowych, które pomogą Ci osiągnąć sukces.
                        </p>
                    </div>
                    <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-5xl">
                        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                            {services.map((service) => (
                                <div key={service.name} className="feature-card relative pl-16" style={{ color: '#ced0d4' }}>
                                    <dt className="text-base font-semibold leading-7">
                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-[#0077a6]">
                                            <service.icon className="h-6 w-6 text-white transition-colors duration-300 transform-gpu hover:text-[#00fefb]" aria-hidden="true" />
                                        </div>
                                        {service.name}
                                    </dt>
                                    <dd className="mt-2 text-base leading-7 text-gray-400">{service.description}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    );
}
