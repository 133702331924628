import React, { useState } from "react";
import { Button, Modal } from 'flowbite-react';
const Portfolio = () => {
    const [showCard, setShowCard] = useState("all");
    const [openModal, setOpenModal] = useState(false);
    const [openModal2, setOpenModal2] = useState(false);
    const [openModal3, setOpenModal3] = useState(false);
    const handleProject = (category) => {
        setShowCard(category.toLowerCase());
    };
    let preventDragHandler = (e) => {
        e.preventDefault();
    }
    return (
        <>

            <Modal show={openModal} onClose={() => setOpenModal(false)}>
                <Modal.Header>Szczegóły projektu</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            Projekt dla firmy JolCar zakładał stworzenie kompleksowego systemu rezerwacji samochodów, który integruje zarówno front-end, jak i back-end, zapewniając użytkownikom płynne i wygodne doświadczenie podczas rezerwacji pojazdów.
                        </p>
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            Projekt obejmował rozbudowany system rezerwacji, zarządzanie flotą pojazdów, system płatności oraz moduł mailowy, mający na celu skuteczną komunikację z klientami.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setOpenModal(false)}>Zamknij</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={openModal2} onClose={() => setOpenModal2(false)}>
                <Modal.Header>Szczegóły projektu</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            Projekt dla Kliniki dla Maszyn zakładał stworzenie profesjonalnej strony wizytówki w WordPress. Strona miała służyć jako platforma prezentacyjna dla firmy, która oferuje usługi związane z naprawą i serwisem maszyn przemysłowych.
                        </p>
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            Projekt obejmował zaprojektowanie responsywnego i estetycznego interfejsu użytkownika, który umożliwiał szybki dostęp do kluczowych informacji o usługach, kontaktach oraz historii firmy.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setOpenModal2(false)}>Zamknij</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={openModal3} onClose={() => setOpenModal3(false)}>
                <Modal.Header>Szczegóły projektu</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            System CMS opracowany dla przedszkola mp12katowice.pl umożliwia pełną kontrolę nad kluczowymi elementami strony, takimi jak aktualności, galeria oraz najnowsze pliki PDF.
                        </p>
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            Aplikacja została zbudowana w profesjonalnym frameworku Laravel, który stanowi standard w naszych projektach. System umożliwia zarządzanie treścią strony w intuicyjny i prosty sposób, co pozwala na samodzielne zarządzanie treścią strony przez klienta.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setOpenModal3(false)}>Zamknij</Button>
                </Modal.Footer>
            </Modal>



            <section className="portfolio-container pt-20 pb-12 lg:pt-[120px] lg:pb-[90px] dark:bg-dark" id="portfolio">
                <div className="container pc1 mx-auto">
                    <div className="flex flex-wrap -mx-4">
                        <div className="w-full px-4">
                            <div className="mx-auto mb-[60px] max-w-[550px] text-center">

                                <h2 className="text-[#0077a6] mb-3 text-3xl leading-[1.208] font-bold sm:text-4xl md:text-[40px]">
                                    Nasze ostatnie realizacje
                                </h2>
                                <p className="text-body-color text-[#bbd0d4] dark:text-dark-6">
                                 Zobacz przykładowe realizacje projektów oraz ich szczegóły, wykonanych przez nasz zespół.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="w-full flex flex-wrap justify-center ">
                        <div className="w-full px-4">
                            <ul className="flex flex-wrap justify-center mb-12">
                                <li className="mb-1">
                                    <button
                                        onClick={() => handleProject("all")}
                                        className={`inline-block rounded-lg py-2 px-5 text-center text-base font-semibold transition md:py-3 lg:px-8 ${
                                            showCard === "all"
                                                ? "activeClasses bg-primary text-white"
                                                : "inactiveClasses text-[#a9c6ce] dark:text-dark-6 hover:bg-primary hover:text-white"
                                        }`}
                                    >
                                        Wszystkie projekty
                                    </button>
                                </li>
                                <li className="mb-1">
                                    <button
                                        onClick={() => handleProject("strony internetowe")}
                                        className={`inline-block rounded-lg py-2 px-5 text-center text-base font-semibold transition md:py-3 lg:px-8 ${
                                            showCard === "strony internetowe"
                                                ? "bg-primary text-white"
                                                : "text-[#a9c6ce] dark:text-dark-6 hover:bg-primary hover:text-white"
                                        }`}
                                    >
        <span
            className={showCard === "strony internetowe" ? "font-bold text-white" : ""}
        >
            Strony internetowe
        </span>
                                    </button>
                                </li>
                                <li className="mb-1">
                                    <button
                                        onClick={() => handleProject("websites")}
                                        className={`inline-block rounded-lg py-2 px-5 text-center text-base font-semibold transition md:py-3 lg:px-8 ${
                                            showCard === "websites"
                                                ? "activeClasses bg-primary text-white"
                                                : "inactiveClasses text-[#a9c6ce] dark:text-dark-6 hover:bg-primary hover:text-white"
                                        }`}
                                    >
                                        Systemy CMS
                                    </button>
                                </li>
                                <li className="mb-1">
                                    <button
                                        onClick={() => handleProject("marketing")}
                                        className={`inline-block rounded-lg py-2 px-5 text-center text-base font-semibold transition md:py-3 lg:px-8 ${
                                            showCard === "marketing"
                                                ? "activeClasses bg-primary text-white"
                                                : "inactiveClasses text-[#a9c6ce] dark:text-dark-6 hover:bg-primary hover:text-white"
                                        }`}
                                    >
                                        API's & Plugins
                                    </button>
                                </li>
                                <li className="mb-1">
                                    <button
                                        onClick={() => handleProject("development")}
                                        className={`inline-block rounded-lg py-2 px-5 text-center text-base font-semibold transition md:py-3 lg:px-8 ${
                                            showCard === "development"
                                                ? "activeClasses bg-primary text-white"
                                                : "inactiveClasses text-[#a9c6ce] dark:text-dark-6 hover:bg-primary hover:text-white"
                                        }`}
                                    >
                                        Aplikacje
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-4">
                        <PortfolioCard
                            openModalFunction={() => setOpenModal(true)}
                            ImageHref="img/jolcar-preview.png"
                            category="strony internetowe"
                            title="JolCar.com.pl"
                            showCard={showCard}
                        />
                        <PortfolioCard
                            openModalFunction={() => setOpenModal2(true)}
                            ImageHref="img/klinika-preview.png"
                            category="strony internetowe"
                            title="Klinikadlamaszyn.pl"
                            showCard={showCard}
                        />
                        <PortfolioCard
                            openModalFunction={() => setOpenModal3(true)}
                            ImageHref="img/mp12-preview.png"
                            category="strony internetowe"
                            title="mp12katowice.pl"
                            showCard={showCard}
                        />

                    </div>
                </div>
            </section>
        </>
    );
};

export default Portfolio;

const PortfolioCard = ({
                           showCard,
                           category,
                           ImageHref,
                           title,
                           openModalFunction
                       }) => {
    let preventDragHandler = (e) => {
        e.preventDefault();
    }
    return (
        <>
            <div
                className={`portfolio-card px-4 md:w-1/2 xl:w-1/3 ${
                    showCard === "all" || showCard === category.toLowerCase()
                        ? "block"
                        : "hidden"
                }`}
            >
                <div className="relative mb-12 ">
                    <div className="overflow-hidden rounded-[10px]">
                        <img onDragStart={preventDragHandler} src={ImageHref} alt="portfolio" className="w-full portfolio-img" />
                    </div>
                    <div className="relative overflow-y-hidden h-[170px] z-10 mx-7 -mt-10 rounded-lg bg-[#131b33] dark:bg-dark-2 py-[34px] px-3 text-center shadow-portfolio shadow-box-dark">
            <span className="category-span text-[#a9c6ce] mb-2 block text-sm font-medium">
              {category}
            </span>
                        <h3 className="text-[#0077a6] mb-5 text-xl font-bold">{title}</h3>
                        <button
                            onClick={openModalFunction}
                            className="text-[#a9c6ce] dark:text-dark-6 hover:border-primary hover:bg-primary inline-block rounded-md border border-[#a9c6ce] dark:border-dark-3 py-[10px] px-7 text-sm font-medium transition hover:text-white"
                        >
                            Szczegóły projektu
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
